<template>
  <base-section id="k-d-a-blog-header" space="0">
    <base-img
      :src="require('@/assets/bg/bg-blog.png')"
      :height="g_bLowestBr ? 186 : g_bBaseBr ? 320 : 640"
    >
      <v-container fluid class="pa-0 fill-height">
        <v-row :style="{ padding: g_bLowerBr ? '24px' : '72px' }" no-gutters>
          <v-col cols="auto">
            <component
              :is="m_objContent.strComponent"
              :class="`kda-ts-${g_bLowerBr ? '28' : '80'}pt wt-extrabold ln-160 white--text`"
              style="max-width: 1038px"
              v-html="m_objContent.htmlText"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-img>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import BusinessDetails from '@/mixins/business-details';

export default {
  name: 'KDAlogHeader',

  mixins: [BusinessDetails, BaseBreakpoint],

  computed: {
    m_objContent() {
      return {
        htmlText: this.$vuetify.lang.t('$vuetify.kda.blog.header'),
        strComponent: 'h1'
      };
    }
  }
};
</script>
